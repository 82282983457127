// src/pages/booking/BookingPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../hooks/useToast';
import { 
 Building2, 
 Calendar, 
 Users, 
 ArrowLeft,
 AlertTriangle,
 CreditCard,
 Clock,
 Mail
} from 'lucide-react';
import { Button } from '../../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Separator } from '../../components/ui/separator';
import hotelService from '../../services/api/hotels/HotelService';
import bookingService from '../../services/booking/BookingService';
import { ROUTES } from '../../config/paths';
import { CreateBookingParams } from '../../types/booking';

const BookingPage = () => {
 const { currentUser } = useAuth();
 const { toast } = useToast();
 const navigate = useNavigate();
 const [searchParams] = useSearchParams();

 const [hotel, setHotel] = useState<any>(null);
 const [room, setRoom] = useState<any>(null);
 const [loading, setLoading] = useState(true);
 const [submitting, setSubmitting] = useState(false);
 const [error, setError] = useState<string | null>(null);

 // Get params from URL
 const bookingParams = {
   hotelId: searchParams.get('hotelId'),
   roomId: searchParams.get('roomId'),
   checkIn: new Date(searchParams.get('checkIn') || ''),
   checkOut: new Date(searchParams.get('checkOut') || ''),
   adults: parseInt(searchParams.get('adults') || '2', 10),
   children: parseInt(searchParams.get('children') || '0', 10),
   price: parseFloat(searchParams.get('price') || '0')
 };

 const loadHotelDetails = async () => {
  try {
    setLoading(true);
    setError(null);

    if (!bookingParams.hotelId) {
      throw new Error('Hotel ID is required');
    }

    const hotelData = await hotelService.getHotelDetails(bookingParams.hotelId);
    console.log('Hotel data:', hotelData);
    setHotel(hotelData);

    if (bookingParams.roomId && hotelData.details?.roomTypes) {
      const roomIdParts = bookingParams.roomId.split('_');
      const roomIndex = parseInt(roomIdParts[2]);

      console.log('Looking for room at index:', roomIndex);
      console.log('Available rooms:', hotelData.details.roomTypes);

      const selectedRoom = hotelData.details.roomTypes[roomIndex];

      if (!selectedRoom) {
        console.log('Room not found at index:', roomIndex);
        throw new Error('Selected room not found');
      }

      // Убедимся, что адрес - это строка
      const roomWithId = {
        ...selectedRoom,
        id: bookingParams.roomId,
        // Форматируем адрес, если это объект
        address: typeof selectedRoom.address === 'object' 
          ? formatAddress(selectedRoom.address) 
          : selectedRoom.address || ''
      };

      console.log('Found room:', roomWithId);
      setRoom(roomWithId);
    } else {
      throw new Error('Room information not available');
    }

  } catch (error) {
    console.error('Error loading hotel details:', error);
    setError(error instanceof Error ? error.message : 'Failed to load hotel details');
    toast({
      title: "Error",
      description: error instanceof Error ? error.message : 'An error occurred',
      variant: "destructive"
    });
  } finally {
    setLoading(false);
  }
};

// Добавим функцию форматирования адреса
const formatAddress = (address: any): string => {
  if (!address) return '';
  
  const parts = [];
  if (address.lines) parts.push(...address.lines);
  if (address.city) parts.push(address.city);
  if (address.country) parts.push(address.country);
  
  return parts.join(', ');
};

 useEffect(() => {
   const requiredParams = ['hotelId', 'roomId', 'checkIn', 'checkOut', 'adults', 'price'];
   const missingParams = requiredParams.filter(param => !searchParams.get(param));

   if (missingParams.length > 0) {
     toast({
       title: "Error",
       description: "Invalid booking parameters",
       variant: "destructive"
     });
     navigate(-1);
     return;
   }

   // Check date validity
   const checkIn = new Date(searchParams.get('checkIn') || '');
   const checkOut = new Date(searchParams.get('checkOut') || '');

   if (isNaN(checkIn.getTime()) || isNaN(checkOut.getTime()) || checkIn >= checkOut) {
     toast({
       title: "Error",
       description: "Invalid dates selected",
       variant: "destructive"
     });
     navigate(-1);
     return;
   }

   loadHotelDetails();
 }, [searchParams]);

 const handleSubmit = async () => {
   try {
     setSubmitting(true);
     setError(null);

     if (!currentUser) {
       const currentUrl = window.location.href;
       navigate(ROUTES.LOGIN + `?redirect=${encodeURIComponent(currentUrl)}`);
       return;
     }

     const bookingData: CreateBookingParams = {
       hotelId: hotel.id,
       roomId: room.id,
       userId: currentUser.uid,
       checkIn: bookingParams.checkIn.toISOString(),
       checkOut: bookingParams.checkOut.toISOString(),
       guests: {
         adults: bookingParams.adults,
         children: bookingParams.children
       },
       price: {
         base: bookingParams.price,
         tax: bookingParams.price * 0.2,
         total: bookingParams.price * 1.2,
         currency: 'USD'
       },
       userDetails: {
         name: currentUser.displayName || '',
         email: currentUser.email || '',
         phone: currentUser.phoneNumber || ''
       },
       hotelDetails: {
         name: hotel.name,
         address: hotel.location.address,
         checkIn: hotel.details?.checkIn || '15:00',
         checkOut: hotel.details?.checkOut || '12:00',
         phone: hotel.contact?.phone,
         email: hotel.contact?.email
       },
       roomDetails: {
         name: room.name,
         type: room.type,
         description: room.description
       }
     };

     const booking = await bookingService.createBooking(bookingData);

     toast({
       title: "Booking Created",
       description: "Your booking has been successfully created!"
     });

     navigate(ROUTES.getBookingConfirmationPath(booking.id));

   } catch (error) {
     console.error('Booking error:', error);
     setError(error instanceof Error ? error.message : 'Failed to create booking');
     toast({
       title: "Booking Failed",
       description: error instanceof Error ? error.message : 'An error occurred',
       variant: "destructive"
     });
   } finally {
     setSubmitting(false);
   }
 };

 if (loading) {
   return (
     <div className="min-h-screen bg-gray-50 py-12">
       <div className="max-w-3xl mx-auto px-4">
         <Card>
           <CardContent className="p-6">
             <div className="flex items-center justify-center h-64">
               <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
             </div>
           </CardContent>
         </Card>
       </div>
     </div>
   );
 }

 if (error || !hotel || !room) {
   return (
     <div className="min-h-screen bg-gray-50 py-12">
       <div className="max-w-3xl mx-auto px-4">
         <Card>
           <CardContent className="p-6">
             <div className="text-center">
               <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
               <h2 className="text-lg font-semibold mb-2">
                 {error || 'Invalid booking parameters'}
               </h2>
               <Button 
                 onClick={() => navigate(-1)}
                 variant="outline"
               >
                 <ArrowLeft className="w-4 h-4 mr-2" />
                 Go Back
               </Button>
             </div>
           </CardContent>
         </Card>
       </div>
     </div>
   );
 }

 const nights = Math.ceil(
   (bookingParams.checkOut.getTime() - bookingParams.checkIn.getTime()) / 
   (1000 * 60 * 60 * 24)
 );

 return (
   <div className="min-h-screen bg-gray-50 py-12">
     <div className="max-w-3xl mx-auto px-4">
       {/* Header */}
       <div className="mb-6">
         <Button 
           variant="ghost" 
           onClick={() => navigate(-1)}
           className="mb-4"
         >
           <ArrowLeft className="w-4 h-4 mr-2" />
           Back to Hotel
         </Button>
         <h1 className="text-2xl font-bold">Complete Your Booking</h1>
       </div>

       {/* Booking Details */}
       <Card className="mb-6">
         <CardHeader>
           <CardTitle>Booking Details</CardTitle>
         </CardHeader>
         <CardContent className="space-y-6">
           {/* Hotel Info */}
           <div className="flex items-start gap-3">
             <Building2 className="w-5 h-5 text-gray-400 mt-1" />
             <div>
             <div className="font-medium">{hotel.name}</div>
              {/* Убедимся, что адрес - это строка */}
              <div className="text-sm text-gray-600">
                {typeof hotel.location.address === 'object' 
                  ? formatAddress(hotel.location.address)
                  : hotel.location.address || ''}
              </div>
              <div className="text-sm text-gray-600 mt-1">
                {room?.name} {room?.type && `• ${room.type}`}
              </div>
             </div>
           </div>

           {/* Dates */}
           <div className="flex items-start gap-3">
             <Calendar className="w-5 h-5 text-gray-400 mt-1" />
             <div>
               <div className="font-medium">
                 {bookingParams.checkIn.toLocaleDateString()} - {bookingParams.checkOut.toLocaleDateString()}
               </div>
               <div className="text-sm text-gray-600">
                 {nights} {nights === 1 ? 'night' : 'nights'}
               </div>
             </div>
           </div>

           {/* Guests */}
           <div className="flex items-start gap-3">
             <Users className="w-5 h-5 text-gray-400 mt-1" />
             <div>
               <div className="font-medium">
                 {bookingParams.adults} {bookingParams.adults === 1 ? 'Adult' : 'Adults'}
                 {bookingParams.children > 0 && `, ${bookingParams.children} ${bookingParams.children === 1 ? 'Child' : 'Children'}`}
               </div>
             </div>
           </div>

           {/* Price */}
           <Separator />
           <div className="space-y-2">
             <div className="flex justify-between text-sm">
               <span>Room rate × {nights} nights</span>
               <span>${bookingParams.price * nights}</span>
             </div>
             <div className="flex justify-between text-sm">
               <span>Taxes & fees (20%)</span>
               <span>${(bookingParams.price * nights * 0.2).toFixed(2)}</span>
             </div>
             <Separator />
             <div className="flex justify-between font-bold">
               <span>Total</span>
               <span>${(bookingParams.price * nights * 1.2).toFixed(2)}</span>
             </div>
           </div>
         </CardContent>
       </Card>

       {/* Additional Info */}
       <Card className="mb-6">
         <CardContent className="p-6">
           <div className="space-y-4">
             <div className="flex items-center gap-2 text-sm">
               <Clock className="w-4 h-4 text-gray-400" />
               <span>Check-in time: {hotel.details?.checkIn || '15:00'}</span>
             </div>
             <div className="flex items-center gap-2 text-sm">
               <Mail className="w-4 h-4 text-gray-400" />
               <span>
                 Confirmation will be sent to: {currentUser?.email || 'Sign in to complete booking'}
               </span>
             </div>
           </div>
         </CardContent>
       </Card>

       {/* Submit Button */}
       <div className="space-y-4">
         <Button 
           onClick={handleSubmit}
           className="w-full"
           disabled={submitting}
         >
           {submitting ? (
             <>
               <div className="animate-spin mr-2">●</div>
               Processing...
             </>
           ) : (
             <>
               <CreditCard className="w-4 h-4 mr-2" />
               Complete Booking
             </>
           )}
         </Button>

         {/* Cancellation Policy */}
         <div className="text-xs text-gray-500 text-center">
           Free cancellation until 24 hours before check-in
         </div>
       </div>
     </div>
   </div>
 );
};

export default BookingPage;